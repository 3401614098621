import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import {
   Card,
   CardHeader,
   CardContent,
} from '@material-ui/core'; 
import { 
   APage,
   AGrid,
   AInput,  
   ATextArea,
   AButton,
   AForm,
   ALoader,
   ASelect,
   ADropzone
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import {
   CREATE_EXECUTIVE,
   GET_ONE_EXECUTIVE,
   UPDATE_EXECUTIVE
} from './api'
import { 
   MUTATION_CALL_BACK,
   QUERY_CALL_BACK
} from '../../utils/graphql.config'
import { EXECUTIVE_IMAGE_UPLOAD } from '../../config';
import { GET_ONE_APP_USER } from '../customers/api';
import CallHistoryReport from '../reports/CallHistoryReport';

const options = [
    {value:'Available', label:'Available'},
    {value:'Offline', label:'Offline'}
]

const type_options = [
   {value:'Normal', label:'Normal', cost:1},
   {value:'Professional', label:'Professional', cost: 3}
]

const ExecutiveForm = () =>{

   const params = useParams()
   const [firstName, setName] = useState('')
   const [mobile, setMobile] = useState('')
   const [password, setPassword] = useState('')
   const [status, setStatus] = useState(null)
   const [account_type, setAccountType] = useState(null)
   const [call_cost, setCallCost] = useState('')
   const [profession, setProfession] = useState('')
   const [file, setFile ] = useState(null)
   const [authId, setAuthId] = useState(null)
   const [uid, setUid] = useState('')
   const [imageUploading, setImageUploading] = useState(false)

   var id = null
   if(params && params.id) id = params.id 

   const SuccessMessage = 'Executive saved successfully'
   const ErrorMessage = 'Executive saving failed'

   const { register, handleSubmit, errors } = useForm(); 
   const mutationCallBack = (success, data)=>{
      if(success){
         if(file && file instanceof File){
            setImageUploading(true)
            const form = new FormData()
            form.append('file', file)
            if(uid){
               form.append('id', uid)
            }else{
               form.append('id', data.createExecutiveUser.id)
            }
            axios.post(EXECUTIVE_IMAGE_UPLOAD, form).then((res)=>{
               setImageUploading(false)
            }).catch((e)=>{
               setImageUploading(false)
            })
         }
         if(!id){
            setName('')
            setMobile('')
            setPassword('')
            setStatus(null)
            setProfession('')
            setAccountType(null)
            setCallCost('')
         }
      }
   }

   const queryCallBack = (success, data)=>{
      if(success && data){
         const obj = data.GetOneExecutiveUser
         if(obj){
            setUid(obj.id)
            setAuthId(obj.authUser.id)
            setName(obj.authUser.firstName)
            setMobile(obj.authUser.mobile)
            setPassword(obj.authUser.password)
            setStatus({value:obj.status, label:obj.status})
            setProfession(obj.profession)
            setCallCost(obj.call_cost)
            setAccountType({value:obj.account_type, label:obj.account_type})
         }
      }
   }

   const [addExecutive, { loading }] = useMutation(CREATE_EXECUTIVE, 
   MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

   const [ updateExecutive, { loading:updateLoading }] = useMutation(UPDATE_EXECUTIVE, 
   MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

   const { loading:getLoading } = useQuery(
      GET_ONE_EXECUTIVE, 
      { 
         variables: {condition: {authUser: id} }, 
         skip: id?false:true,
         ...QUERY_CALL_BACK(queryCallBack)
      }
   );

   const onSubmit = (data)=>{
      var data = {
         ...data, 
         username: mobile,
         lastName: firstName,
         status: status.value,
         call_cost: parseInt(call_cost),
         account_type: account_type.value
      }
      if(id){
         onUpdate({...data, id: uid})
      }else{
         onSave({...data})
      }
   }

   const onSave = (data)=>{
      addExecutive({ 
         variables: { data }
      })
   }

   const onDrop = (f)=>{
      setFile(f[0])
   }

   const onUpdate = (data)=>{
        updateExecutive({ 
            variables: { data }
        })
   }

   const setAccountTypeChange = (type)=>{
      setAccountType(type)
      setCallCost(type.cost)
   }

   if(loading || getLoading || updateLoading || imageUploading){
      return <ALoader/>
   }

   return(
      <APage>
         <PageHeader>Executive Form</PageHeader>
         <AGrid container spacing={2} className="page-details">
            <AGrid item md={12}> 
               <Card className="search-header page-form">
                     <CardHeader title="Executive Info" />
                     <CardContent>
                     <AForm onSubmit={handleSubmit(onSubmit)}>
                     <AGrid container spacing={2}>
                        <AGrid item md={12}>
                           <ADropzone onDrop={onDrop} src={file}/>
                        </AGrid>
                        <AGrid item md={6}>
                        <AInput 
                              label="Executive Name"
                              name="firstName"
                              errors={errors}
                              value={firstName}
                              onChange={(e)=>setName(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                            <AInput 
                                label="Profession"
                                name="profession"
                                errors={errors}
                                value={profession}
                                onChange={(e)=>setProfession(e.target.value)}
                                register={register}
                                rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                            <AInput 
                                label="Mobile"
                                name="mobile"
                                errors={errors}
                                value={mobile}
                                onChange={(e)=>setMobile(e.target.value)}
                                register={register}
                                rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                           <AInput 
                              label="Password"
                              name="password"
                              errors={errors}
                              value={password}
                              onChange={(e)=>setPassword(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                         </AGrid>
                        <AGrid item md={6}>
                           <ASelect 
                              label="Type"
                              name="status"
                              errors={errors}
                              value={account_type}
                              options={type_options}
                              onChange={(e)=>setAccountTypeChange(e)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                       
                         <AGrid item md={6}>
                           <AInput 
                              label="Call Cost"
                              name="call_cost"
                              errors={errors}
                              value={call_cost}
                              onChange={(e)=>setCallCost(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                         </AGrid>
                        <AGrid item md={6}>
                           <ASelect 
                              label="Status"
                              name="status"
                              errors={errors}
                              value={status}
                              options={options}
                              onChange={(e)=>setStatus(e)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        
                        <AGrid item md={12}>
                           <AButton type="submit" variant="contained"
                           color="default"
                           className="bg-btn mr">SAVE</AButton>
                        </AGrid>
                     </AGrid>
                     </AForm>
                     </CardContent>
               </Card>           
            </AGrid> 
        </AGrid>
        {authId?<CallHistoryReport 
         limit={10}
         disableExecutive = {true}
         condition={{executive: authId}}/>:null}
      </APage>   
   )
}
export default ExecutiveForm;