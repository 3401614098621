import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class AEditor extends Component {
    state = {  }

    constructor(props){
        super(props)

        this.onChange = this.onChange.bind(this)
    }

    onChange( event, editor ){
        const data = editor.getData();
        const {onChange} = this.props
        if(onChange) onChange(data)
    }

    render() { 
        const {data} = this.props
        return ( 
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={data}
                    onChange={this.onChange}
                />
            </div>
            
         );
    }
}

AEditor.defaultProps = {
    data: ''
}
 
export default AEditor;