import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import moment from 'moment'

import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage,
    AGrid,
    AInput,
    AButton,
    ADateTime
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import { GET_HISTORIES} from './api';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import { URL_PREFIX } from '../../utils/constants';
import CustomerSelect from '../customers/CustomerSelect';
import ExecutiveSelect from '../executives/ExecutiveSelect';
import CallStatusSelect from './CallStatusSelect';
import { getColorForCallStatus } from '../../utils';

export default function CallHistoryReport(props){

    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const [executive, setExecutive] = useState(0)
    const [customer, setCustomer] = useState(0)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)
    const [callStatus, setCallStatus] = useState(0)
    const [condition, setCondition] = useState({})
    const limit = 20 

    const { loading, error, data, refetch } = useQuery(GET_HISTORIES, {
        variables: { skip:skip, limit:props.limit?props.limit:limit,  
            condition:props.condition?{...props.condition, ...condition}:condition, sort:"-updatedAt"},
    });

    React.useEffect(() => {
        refetch()
    }, [skip, condition])

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    const onFilter = ()=>{
        if(executive)
        setCondition({...condition, outbound_client: executive.code})
        if(customer)
        setCondition({...condition, inbound_client: customer.code})
        if(startTime)setCondition({...condition, updatedAt:{___gte:startTime}})
        if(endTime)setCondition({...condition, updatedAt:{___lte:endTime}})
        if(callStatus) setCondition({...condition, call_status: callStatus.value})
    }

    const onClear = ()=>{
        setCondition({})
    }

    const HeaderFilter = ()=>{
        if(props.disableFilter){
            return null
        }
        return (
        <AGrid spacing={2} container className="search-header" direction="row"  alignItems="center">
            {props.disableExecutive ? null : <AGrid item md={3}>
                <ExecutiveSelect value={executive} onChange={(e)=>setExecutive(e)}/>
            </AGrid> }
            {props.disableCustomer ? null :
            <AGrid item md={3}>
                <CustomerSelect value={customer} onChange={(e)=>setCustomer(e)}/>
            </AGrid>}
            <AGrid item md={3}>
                <ADateTime value={startTime} onChange={(e)=>setStartTime(e)} type="both"/>
            </AGrid>
            <AGrid item md={3}>
                <ADateTime value={endTime} onChange={(e)=>setEndTime(e)} type="both"/>
            </AGrid>
            <AGrid item md={3}>
                <CallStatusSelect value={callStatus} onChange={(e)=>setCallStatus(e)}/>
            </AGrid>
            <AGrid item md={4} >
                <AButton  variant="contained"
                    style={{marginRight: 10}}
                    onClick={onClear}>RESET</AButton>
                <AButton  variant="contained"
                    color="default"
                    onClick={onFilter}
                    className="bg-btn mr">FILTER</AButton>
            </AGrid>
        </AGrid>
    )}

    if(loading){
        return (
            <APage >
                <PageHeader export={false} add={false}>Call History</PageHeader>
                {HeaderFilter()}
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.getManyCallHistories || data.getManyCallHistories.length < 1){
        return (
            <APage>
                <PageHeader export={false} add={false}>Call History</PageHeader>
                {HeaderFilter()}
                <NoDataFound/>
            </APage>
        )
    } 

    const histories = data.getManyCallHistories;
    const count = data.GetCallHistoriesCount;  

    return(
        <APage>
            <PageHeader export={false} add={false}>Call History</PageHeader>
            {HeaderFilter()}
            <ATable>
                <ATableHead>
                <ATableRow> 
                        {props.disableCustomer ? null :<ATableCell>Customer</ATableCell> }
                        {props.disableExecutive ? null :<ATableCell>Executive</ATableCell>}
                        <ATableCell>Duration (sec)</ATableCell>
                        <ATableCell>Coins Used</ATableCell>
                        <ATableCell>Status</ATableCell>
                        <ATableCell>Time</ATableCell>
                        <ATableCell>Actions</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {histories.map((item,index)=>(
                <ATableRow key={index}> 
                        {props.disableCustomer ? null :<ATableCell> 
                            <Link to={'/update-wallet/'+item.customer.id}>
                                <p>{item.customer.firstName}</p> 
                            </Link>
                            <p>{item.inbound_client}</p>
                            <p>{item.inbound_call_sid}</p>
                        </ATableCell>}
                        {props.disableExecutive ? null :<ATableCell>  
                            <Link to={'/edit-executive/'+item.executive.id}>
                                <p>{item.executive.firstName}</p> 
                            </Link>
                            <p>{item.outbound_client}</p> 
                            <p>{item.outbound_call_sid}</p>
                        </ATableCell>}
                            
                        <ATableCell> {item.call_duration} </ATableCell>
                        <ATableCell> <p>Free: {item.free_coins_used}</p>
                        <p>Premium: {item.coins_used}</p> </ATableCell>
                        <ATableCell><span style={{color: getColorForCallStatus(item.call_status) }}>
                            {item.call_status}</span></ATableCell>
                        <ATableCell> {moment(item.updatedAt).format('DD-MM-YYYY hh:mm a')}</ATableCell>
                        <ATableCell>
                            <IconButton 
                                aria-label="edit" 
                                component={Link} 
                                to={URL_PREFIX.ADMIN+"/call-details/"+item.id+'/'+item.inbound_call_sid}> <VisibilityIcon /></IconButton>
                        </ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}