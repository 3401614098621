import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'

const GET_HISTORIES = gql`
query(${PAGINATION_PARAMS}){
    getManyCallHistories(${PAGINATION_VALUES}){
      id
      customer{
        id
        firstName
        mobile
      }
      executive{
        id
        firstName
        mobile
      }
      outbound_client
      inbound_client
      call_duration
      outbound_call_duration
      inbound_call_sid
      outbound_call_sid
      call_status
      updatedAt
      free_coins_used
      coins_used
    }
    GetCallHistoriesCount(condition:$condition)
}
`

const GET_USER_SUBSCRIPTIONS = gql`
query(${PAGINATION_PARAMS}){
  getManyUserSubscription(${PAGINATION_VALUES}){
    id
    price
    authUser{
      firstName
      mobile
    }
    plan{
      name
    }
    payed_amount
    payment_status
    order_id
    transaction_id
    updatedAt
  }
  GetUserSubscriptionsCount
}
`

const GET_CALL_DETAILS = gql`
query($id: String!, $executive: String!, $sid: String!){
  getOneCallHistory(condition:{_id: $id}){
    id
    customer{
      id
      firstName
    }
    executive{
      id
      firstName
    }
    call_duration
    coins_used
    free_coins_used
    inbound_client
    outbound_client
    call_status
    outbound_call_duration
    outbound_call_sid
    inbound_call_sid
    updatedAt
    rating
    message
    initial_executive_coin
    final_executive_coin
    initial_customer_coin
    final_customer_coin
    start_time
    end_time
  }
  GetOneExecutiveUser(condition:{executive_code: $executive}){
    id
    executive_code
    call_cost
    authUser{
      id
      firstName
    }
  }
  getManyCallLogsHistories(sort:"createdAt", condition:{call_sid: $sid}){
    event_data
    createdAt
    call_sid
  }
}
`

export {
    GET_HISTORIES,
    GET_USER_SUBSCRIPTIONS,
    GET_CALL_DETAILS
}