import React from 'react'
import { Button, IconButton } from '@material-ui/core';

export default function AButton({
    children,
    icon,
    ...props
}){

    if(icon){
        return <IconButton {...props}>{children}</IconButton>
    }

    return <Button {...props}>{children}</Button>
} 