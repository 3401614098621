import { useQuery } from '@apollo/client';
import React from 'react'
import { ASelect } from '../../components/basic';
import { GET_PLANS } from './api';

export default function SubscriptionSelect({value, onChange}){

    const { loading, error, data, refetch } = useQuery(GET_PLANS, {
        variables: { condition: {price: { ___gte:0}}},
    });

    var options = []
    if(data && data.getManySubscriptionPlans && data.getManySubscriptionPlans.length > 0){
        options = data.getManySubscriptionPlans.map((subscription)=>{
            return {value: subscription.id, label: subscription.name, coins: subscription.number_of_coins}
        })
    }

    return(
        <ASelect
            options={options}
            value={value}
            onChange={onChange}
            label="Subscription Plan"
            />
    )

}