import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import {
  AButton,
  AListItem,
  makeStyles
} from '../../../components/basic';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  child,
  childrens,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  if(child == true && childrens) {
    return (
      <React.Fragment>
      <AListItem button  disableGutters  className={clsx(classes.item, className)}>
      <AButton
        activeclassname={classes.active}
        className={classes.button}
        onClick={handleClick}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </AButton>
      {open ? <ExpandLess /> : <ExpandMore />}
    </AListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding className="sas-submenu">
      {childrens.map((item, m) => (
        <AListItem  className={classes.nested} key={m}>
        <AButton
        activeclassname={classes.active}
        className={classes.button}
        component={RouterLink}
        to={item.href}
      >
        <span className={classes.title}>
          {item.title}
        </span>
      </AButton>
        </AListItem>
        ))}
      </List>
    </Collapse>
    </React.Fragment>
    )}
    
if(child==false && !childrens) {
  return (
    <AListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <AButton
        activeclassname={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </AButton>
    </AListItem>
  )
}

}

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
