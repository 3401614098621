import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import {
  makeStyles
} from '@material-ui/core';
import { 
    APage,
    ABox,  
    AInput, 
    ACard,
    AContainer,
    AButton,
    ATypography,
    ALoader
  } from '../../components/basic';
import Logo from  '../../components/icons/Logo';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PERMISSIONS, LOGIN } from './api';
import { connect } from 'react-redux';
import { loginRedux } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const PREFIX = '/'

const Login = ({
  onLoginRedux
}) => {

  const classes = useStyles();
  let navigate = useNavigate();
  const [username, setUserName] = useState('')
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [password, setPassword] = useState('')

  const [ login, { loading, data }] = useMutation(LOGIN, {
    onCompleted(data){
      if(data && data.login && data.login.accessToken && data.login.authUser){
        if(data.login.authUser.roles && data.login.authUser.roles.includes('superadmin') ){
          localStorage.setItem('callmatez_admin_token', data.login.accessToken)
          onLoginRedux(data.login.accessToken, data.login.authUser, 'superadmin')
          setLoginSuccess(true)
          navigate('/');
        }
      }
    },
    onError(e){
    }
  });

  const onLogin = ()=>{
    if(username && password){
      login({
        variables:{
          data:{
            username,
            password,
            clientSecret:"secret",
            clientName:"default"
          }
        }
      })
    }
  }

  return (
    <APage
      className={classes.root}
      title="Login"
    >
      {loading ? <ALoader/> : null}
      <ABox
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <AContainer maxWidth="sm">
          <ACard className="login-wrapper">
              <form >
                <ABox>
                <Logo className="logo"/>    
                </ABox>  
                <ABox mb={2}>
                  <ATypography
                    color="textPrimary"
                    variant="h6"
                    align="center"
                  >
                 Sign in on the internal platform
                  </ATypography>
                </ABox>
                <AInput
                  fullWidth
                  label="User Name"
                  margin="normal"
                  name="username"
                  type="text"
                  value={username}
                  onChange={(e)=>setUserName(e.target.value)}
                  variant="outlined"
                />
                <AInput
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  variant="outlined"
                />
                <ABox my={2}>
                  <AButton
                    className="bg-btn"
                    fullWidth
                    size="large"
                    onClick={onLogin}
                  >
                    Login
                  </AButton>
                </ABox>
              </form>
              </ACard>
        </AContainer>
      </ABox>
    </APage>
  );
};

const mapStateToProps = state => ({
  // isConnected: state.network.isConnected
});

const mapDispatchToProps = dispatch => ({
  onLoginRedux:(token, user, role)=>{
    dispatch(loginRedux(token, user, role))
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(Login);