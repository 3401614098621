import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE, 
    CONDITION_PARAM
} from '../../utils/graphql.config'

const GET_DASHBOARD = gql`
query{
    getAdminDashboard{
      totalSales
      totalCalls
    }
    GetExecutiveUserCount
    GetAppUserCount
}
`

export {
    GET_DASHBOARD
}