import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function SingleSelect({
    value,
    options,
    label,
    onChange,
    name,
    onInputChange,
    disabled,
    register,
    rules,
    error,
    ...props
}) {

  const onChangeSearch = (e)=>{
      if(onInputChange)onInputChange(e.target.value)
  }

  const onChangeSelect = (e, value)=>{
      if(onChange) onChange(value)
  }

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      name={name}
      disabled={disabled}
      getOptionLabel={option => option.label}
      onChange={onChangeSelect}
      value={value}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <h4>{option.label}</h4>
        </React.Fragment>
      )}
      renderInput={params => {
          return <TextField {...params} 
                      label={label} 
                      variant="outlined" 
                      fullWidth 
                      error={error}
                      inputRef={register?register(rules):null}
                      onChange={onChangeSearch} />
      }}/>
  );
}