
import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'

const GET_PLANS = gql`
query(${PAGINATION_PARAMS}){
    getManySubscriptionPlans(${PAGINATION_VALUES}){
        id
        name
        number_of_coins
        price
    }
    GetSubscriptionPlansCount(${CONDITION_VALUE})
}
`

const GET_PLAN = gql`
query($condition: JSONObject){
    getOneSubscriptionPlan(condition:$condition){
      id
      name
      description
      number_of_coins
      price
      gst
    }
  }
`


const ADD_PLAN = 
gql`
mutation($data: CreateSubscriptionPlanDto!){
    createSubscriptionPlan(data:$data){
        id
    }
}`

const UPDATE_PLAN = gql`
mutation($data: UpdateSubscriptionPlanDto!){
    updateSubscriptionPlan(data:$data){
      id
    }
}`

const DELETE_PLAN = gql`
mutation($data: DeleteDto!){
    deleteSubscriptionPlan(data:$data){
      id
    }
}`



export {
    GET_PLANS,
    GET_PLAN,
    ADD_PLAN,
    UPDATE_PLAN,
    DELETE_PLAN
}

