import React from 'react';
import { Navigate } from 'react-router-dom';
import { URL_PREFIX } from '../utils/constants';
import DashboardLayout from '../layouts/dashboard';
import NotFound from '../views/common/NotFound';
import Dashboard from '../views/dashboard';
import Login from '../views/auth/Login';
import Customers from '../views/customers/Customers';
import Executives from '../views/executives/Executives';
import ExecutiveForm from '../views/executives/ExecutiveForm';
import Subscriptions from '../views/subscription/Subscriptions';
import SubscriptionForm from '../views/subscription/SubscriptionForm';
import TwilioTest from '../views/common/TwilioTest';
import TransactionReport from '../views/reports/TransactionReport';
import CallHistoryReport from '../views/reports/CallHistoryReport';
import FreePlan from '../views/free-plan/FreePlan';
import FreePlanForm from '../views/free-plan/FreePlanForm';
import About from '../views/pages/About';
import Message from '../views/pages/Message';
import Privacy from '../views/pages/Privacy';
import UpdateCustomerWallet from '../views/customers/UpdateCustomerWallet';
import AppVersions from '../views/app-version/AppVersions';
import AppVersionForm from '../views/app-version/AppVersionForm';
import Notification from '../views/pages/Notification';
import CallHistoryDetailPage from '../views/reports/CallHistoryDetailPage';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Dashboard/> },
      { path: 'customers', element: <Customers /> },
      { path: 'executives', element: <Executives /> },
      { path: 'add-executive', element: <ExecutiveForm /> },
      { path: 'edit-executive/:id', element: <ExecutiveForm /> },
      { path: 'plans', element: <Subscriptions /> },
      { path: 'add-plan', element: <SubscriptionForm /> },
      { path: 'edit-plan/:id', element: <SubscriptionForm /> },
      { path: 'free-plan', element: <FreePlan /> },
      { path: 'update-free-plan/:id', element: <FreePlanForm /> },
      { path: 'transactions', element: <TransactionReport /> },
      { path: 'caller-history', element: <CallHistoryReport /> },
      { path: 'call-details/:id/:sid', element: <CallHistoryDetailPage /> },
      { path: 'about', element: <About /> },
      { path: 'message', element: <Message /> },
      { path: 'privacy', element: <Privacy /> },
      { path: 'notification', element: <Notification /> },
      { path: 'update-wallet/:id', element: <UpdateCustomerWallet /> },
      { path: 'app-versions', element: <AppVersions /> },
      { path: 'add-version', element: <AppVersionForm /> },
    ]
  },
  { path: 'login', element: <Login /> },
  { path: '404', element: <NotFound /> },
  { path: 'twilio', element: <TwilioTest /> },
  // { path: '/', element: <Navigate to={URL_PREFIX.ADMIN} /> },
  // { path: '*', element: <Navigate to="/404" /> }
];

export default routes;
