import React from 'react'
import { GoogleMap, Marker, Polygon, withGoogleMap, withScriptjs } from "react-google-maps"
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager"
const { compose, withProps, lifecycle } = require("recompose");
const _ = require("lodash");
const {
  SearchBox
} = require("react-google-maps/lib/components/places/SearchBox");

const AMap = compose(
  withProps({
    isMarkerShown: true,
    googleMapURL : "https://maps.googleapis.com/maps/api/js?key=AIzaSyBcOlnupQWBoZplstf7REBtcnKNVNj8o7M&v=3.exp&libraries=geometry,drawing,places",
    loadingElement : <div style={{ height: `100%` }} />,
    containerElement : <div style={{ height: `400px` }} />,
    mapElement : <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        bounds: null,
        center: {
          lat: this.props.latitude?this.props.latitude:24.7136, 
          lng: this.props.longitude?this.props.longitude:46.6753
        },
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });
          
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
            address: place.formatted_address
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });
          if(nextMarkers 
            && nextMarkers[0]
            && nextMarkers[0].position
            && this.props.onSelectLocation){
            this.props.onSelectLocation(nextMarkers[0].position.lat(), nextMarkers[0].position.lng(), nextMarkers[0].address)
          }
          // refs.map.fitBounds(bounds);
        },
        onDrawPolygon:(polygon)=>{
          var coords = polygon.getPath().getArray()
          coords = coords.map((c)=>{return [c.lat(), c.lng()]})
          if(coords.length > 1){
            coords.push(coords[0])
          }
          if(this.props.onDrawPolygon){
            this.props.onDrawPolygon(coords)
          }
        }
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <div >
     <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      key={props.reflector?props.reflector:'map'}
      center={props.custom_center?props.custom_center:props.center}
      onBoundsChanged={props.onBoundsChanged}
      defaultCenter={props.defaultCenter}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Type location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `280px`,
            height: `40px`,
            marginTop: `10px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            marginLeft: `10px`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
      {props.markers && props.markers.map((marker, index) =>
        <Marker 
          key={marker.key?marker.key:index} 
          icon={props.tankerIcon?{

            url: 'https://cdn0.iconfinder.com/data/icons/map-markers-2-1/512/xxx002-512.png',
    
            anchor: new window.google.maps.Point(17, 46),
    
            scaledSize: new window.google.maps.Size(37, 37)
    
          }:null}
          label={marker.name}
          position={marker.position} />
      )}
      {props.drawPolygon ?<Polygon
        path={props.polyCords}
        key={1}
        editable={true}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35
        }}
      />:null}
      {props.drawPolygon ?  
      <DrawingManager
      defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
      onPolygonComplete={props.onDrawPolygon}
      defaultOptions={{
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        circleOptions: {
          fillColor: `#ffff00`,
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      }}
    />: null}
    </GoogleMap>
    {props.validator? props.validator.message(props.name, props.latitude, props.rules):null}
  </div>
 
));

AMap.defaultProps = {
  markers : [],
  drawPolygon: false,
  polyCords:[]
}

export default AMap

