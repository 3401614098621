import React from 'react'

import { 
    ASelect
} from '../../components/basic';
import { CallStatus } from '../../utils/constants';

const OPTIONS = Object.entries(CallStatus).map((d)=>{
    return {value: d[1], label: d[0]}
})


export default function CallStatusSelect(props){

    return(
        <ASelect
            options={OPTIONS}
            label="Select call status"
            {...props}
        />
    )
}