
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MultipleSelect from './MultipleSelect';
import SingleSelect from './SingleSelect';

function ASelect({
    isMulti, 
    validator, 
    rules, 
    style, 
    onInputChange,
    register,
    errors,
    ...props
}){

    const [search, setSearch] = useState('')
    var error = false
    if((errors && errors[props.name])) error = true

    const inputChange = (text)=>{
        const value = text.trim()
        if((value.length>0 || search.length>0) 
            && onInputChange){
                onInputChange(value)
        }
        setSearch(value)
    }

    if(isMulti){
        return  <MultipleSelect 
                    {...props} 
                    error={error}
                    inputRef={register?register(rules):null}
                    onInputChange={inputChange}/>
    }
    return <SingleSelect 
                {...props} 
                error={error}
                inputRef={register?register(rules):null}
                onInputChange={inputChange}/>

}

ASelect.propTypes = {
    name : PropTypes.string.isRequired
}

ASelect.defaultProps = {
    name:'select',
    value : ''
}
 
export default ASelect;