const initialState ={
    user: null,
    token: '',
    role:{}
}

export default function authReducer(state = initialState , action){
    switch(action.type){

        case 'LOGIN':
            return {
                user: action.payload.user, 
                token:action.payload.token, 
                role: action.payload.role
            };
        default:
            return state
       
    }
}