import React, { useEffect, useState } from 'react';
import { Link as RouterLink , useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AAppBar,
  ABadge,
  ABox,
  AHidden,
  AButton,
  AToolbar,
  makeStyles
} from '../../../components/basic';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../../components/icons/Logo';
import sasco from '../../../theme/images/logo_square.jpg';
const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [notifications] = useState([]);

  useEffect(()=>{
    const token = localStorage.getItem('callmatez_admin_token')
    if(!token || token == ''){
      navigate('/login')
    }
  })

  const logOut = ()=>{
    localStorage.setItem('callmatez_admin_token', '')
    navigate('/login');
  }

 

  return (
    <AAppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <AToolbar>
        <RouterLink to="/">
          <Logo style={{height:50, width: 50}}/>
        </RouterLink>
        <ABox flexGrow={1} />
        <AHidden mdDown>
          {/* <AButton color="inherit" icon>
            <ABadge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </ABadge>
          </AButton> */}
          <AButton color="inherit" icon onClick={logOut}>
            <InputIcon />
          </AButton>
        </AHidden>
        <AHidden lgUp>
          <AButton
            icon
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </AButton>
        </AHidden>
      </AToolbar>
    </AAppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
