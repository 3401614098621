import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';

import { 
    ABox, 
    ACard, 
    APage, 
    AGrid,
} from '../../components/basic'
import PageHeader from '../../components/common/PageHeader';
import TotalSale from './TotalSales';
import TotalCalls from './TotalCalls';
import TotalCustomers from './TotalCustomers';
import TotalExecutives from './TotalExecutives';
import { GET_DASHBOARD } from './api';
import CallHistoryReport from '../reports/CallHistoryReport';

const Dashboard = () => {

    const [totalCustomers, setTotalCustomers] = useState(0)
    const [totalExecutives, setTotalExecutives] = useState(0)
    const [totalCall, setTotalCall] = useState(0)
    const [totalSale, setTotalSale] = useState(0)

    const { loading, error, data, refetch } = useQuery(GET_DASHBOARD, {
        variables: { },
        onCompleted:(data)=>{
            if(data && data.getAdminDashboard){
                setTotalCall(data.getAdminDashboard.totalCalls)
                setTotalSale(data.getAdminDashboard.totalSales)
            }
            if(data && data.GetExecutiveUserCount){
                setTotalExecutives(data.GetExecutiveUserCount)
            }
            if(data && data.GetAppUserCount){
                setTotalCustomers(data.GetAppUserCount)
            }
        }
    });

    return(
        <APage>
            <PageHeader>Dashboard</PageHeader>
            <AGrid spacing={2} container>
                    <AGrid item xs={3}>
                     <TotalSale count={totalSale}/>
                    </AGrid>
                    <AGrid item xs={3}>
                     <TotalCalls count={totalCall}/>
                    </AGrid>
                    <AGrid item xs={3}>
                     <TotalCustomers count={totalCustomers}/>
                    </AGrid>
                    <AGrid item xs={3}>
                     <TotalExecutives count={totalExecutives}/>
                    </AGrid>
                    <AGrid item xs={12}>
                      <CallHistoryReport limit={10} disableFilter={true}/>
                    </AGrid>
            </AGrid>
        </APage>
    )
}

export default Dashboard