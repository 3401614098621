import { useMutation, useQuery } from '@apollo/client'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import swal from 'sweetalert'
import { AButton, AGrid, ALoader, APage } from '../../components/basic'
import PageHeader from '../../components/common/PageHeader'
import { MUTATION_CALL_BACK, QUERY_CALL_BACK } from '../../utils/graphql.config'
import CallHistoryReport from '../reports/CallHistoryReport'
import SubscriptionSelect from '../subscription/SubscriptionSelect'
import { GET_ONE_APP_USER, GET_USER_WALLET, UPDATE_USER_STATUS, UPDATE_WALLET } from './api'

export default function UpdateCustomerWallet(){

    const params = useParams()
    const [plan, setPlan] = useState(null)
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [balance, setBalance] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [id, setId] = useState('')
    const [uid, setUid] = useState('')
    const [used_coins, setUsedCoins] = useState('')
    const [total_free_coins_used, setTotal_free_coins_used] = useState('')
    const SuccessMessage = 'Wallet updated successfully'
    const ErrorMessage = 'Wallet update failed'

    const queryCallBack = (success, data)=>{
        if(success && data){
            const obj = data.getOneUserWallet
            if(obj){
               setId(obj.authUser.id)
               setName(obj.authUser.firstName)
               setMobile(obj.authUser.mobile)
               setBalance(obj.total_coins)
               setUsedCoins(obj.used_coins)
               setIsActive(obj.authUser.isActive)
               setTotal_free_coins_used(obj.total_free_coins_used)
            }
         }
    }

    const queryCallBack2 = (success, data)=>{
        if(success && data){
            const obj = data.GetOneAppUser
            if(obj){
                setUid(obj.id)
            }
         }
    }

    const { loading:getLoading, refetch } = useQuery(
        GET_USER_WALLET, 
        { 
            variables: {condition: {authUser: params.id} }, 
            skip: params && params.id?false:true,
            ...QUERY_CALL_BACK(queryCallBack)
        }
    );

    const { } = useQuery(
        GET_ONE_APP_USER, 
        { 
            variables: {condition: {authUser: params.id} }, 
            skip: params && params.id?false:true,
            ...QUERY_CALL_BACK(queryCallBack2)
        }
    );


    const mutationCallBack = (success, data)=>{
        if(success){
            if(plan && balance != null){
                setBalance(balance + plan.coins)
            }
            setPlan(null)
            refetch()
        }
    }

    const [updateWallet, { loading }] = useMutation(UPDATE_WALLET, 
        MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

    const [updateUserStatus, { loading: loadingActivation }] = useMutation(UPDATE_USER_STATUS, 
        MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, (success, data)=>{
            if(success){
                setIsActive(!isActive)
                refetch()
            }
        }));

    const onSubmit = ()=>{
        if(!plan) return
        var id = null
        if(params && params.id) id = params.id 
        if(!id) return
        var data = {
           plan: plan.value,
           customer: id
        }
        updateWallet({variables:{
            data
        }})
    }



    const changeUserStatus = ()=>{
        swal({
            title: "Are you sure?",
            text: `Want to ${isActive?'block':'unblock'} user`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                updateUserStatus({ 
                    variables: { data:{ id: uid, isActive: !isActive }}
                })
            } else {
              
            }
          });
    }



    if(loading || getLoading || loadingActivation){
        return (
            <APage>
                <PageHeader export={false} add={false}>Update Wallet</PageHeader>
                <ALoader/>
            </APage>
        )
    }

    return(
        <APage>
            <PageHeader>Update Wallet</PageHeader>
            <AGrid container spacing={2} className="page-details">
                
                <AGrid item md={6}> 
                    <Card className="search-header page-form">
                        <CardHeader title="Customer Balance" />
                        <CardContent>
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Name</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{name}</span>
                                </AGrid>
                            </AGrid>
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Mobile</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{mobile}</span>
                                </AGrid>
                            </AGrid>
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Total Premium Coins Recharged</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{balance}</span>
                                </AGrid>
                            </AGrid>
                            {/* <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Total Free Coins Used</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{total_free_coins_used}</span>
                                </AGrid>
                            </AGrid> */}
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Total Premium Coins Used</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{used_coins}</span>
                                </AGrid>
                            </AGrid>
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <span>Balance</span>
                                </AGrid>
                                <AGrid item md={6}>
                                    <span>{balance - used_coins}</span>
                                </AGrid>
                            </AGrid>
                            <div style={{marginTop: 20}}>
                                <AButton  variant="contained"
                                    color="default"
                                    onClick={changeUserStatus}

                                    className="bg-btn mr">{isActive?"Block Customer":"Unblock Customer"}</AButton>
                            </div>
                        </CardContent>
                    </Card>
                </AGrid>
                <AGrid item md={6}> 
                    <Card className="search-header page-form">
                        <CardHeader title="Plan Info" />
                        <CardContent>
                            <AGrid container spacing={2}>
                                <AGrid item md={6}>
                                    <SubscriptionSelect
                                    value={plan}
                                    onChange={(e)=>setPlan(e)}
                                    />
                                </AGrid>
                                <AGrid item md={12}>
                                    <AButton  variant="contained"
                                    color="default"
                                    onClick={onSubmit}
                                    className="bg-btn mr">SAVE</AButton>
                                </AGrid>
                            </AGrid>
                        </CardContent>
                    </Card>
                </AGrid>
            </AGrid>
            
            <CallHistoryReport 
                limit={10}
                disableCustomer={true}
                condition={{customer: params.id}}/>
        </APage>
    )
}