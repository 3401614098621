import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'

import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import {  GET_MANY_APP_VERSIONS } from './api';
import { URL_PREFIX } from '../../utils/constants';

const PREFIX = '/'+URL_PREFIX.ADMIN

export default function AppVersions(){

    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const [condition, setCondition] = useState({})
    const limit = 10 

    const { loading, error, data, refetch } = useQuery(GET_MANY_APP_VERSIONS, {
        variables: { skip:skip, limit:limit,  condition},
    });

    React.useEffect(() => {
        refetch()
    }, [skip])

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    if(loading){
        return (
            <APage >
                <PageHeader export={false} add={true} to={PREFIX+"/add-version"}>App Versions</PageHeader>
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.getManyAppVersions || data.getManyAppVersions.length < 1){
        return (
            <APage>
                <PageHeader export={false} add={true} to={PREFIX+"/add-version"}>App Versions</PageHeader>
                <NoDataFound/>
            </APage>
        )
    } 

    const versions = data.getManyAppVersions;
    const count = data.GetAppVersionCount;  

    return(
        <APage>
            <PageHeader export={false} add={true} to={PREFIX+"/add-version"}>App Versions</PageHeader>
            <ATable>
                <ATableHead>
                <ATableRow> 
                        <ATableCell>Version</ATableCell>
                        <ATableCell>Platform</ATableCell>
                        <ATableCell>Message</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {versions.map((item,index)=>(
                <ATableRow key={index}> 
                        <ATableCell> {item.version} </ATableCell>
                        <ATableCell> {item.type} </ATableCell>
                        <ATableCell> {item.message} </ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}