import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { mapProps } from 'recompose';

import { 
    ASelect
} from '../../components/basic';
import { GET_APPUSERS } from './api';


export default function CustomerSelect(props){

    const [condition, setCondition] = useState({})
    const limit = 10 
    const skip = 0

    const { loading, error, data, refetch } = useQuery(GET_APPUSERS, {
        variables: { skip:skip, limit:limit,  condition},
    });
    
    const customers = data?.GetManyAppUser?.map((customer)=>{
        return {value: customer.authUser.id, label: customer.authUser.firstName, code:customer.customer_code}
    });

    useEffect(()=>{
        refetch()
    }, [condition])

    const onSearch = (search)=>{
        const customer = {___regex: ".*"+search+'.*', ___options : 'i'}
        setCondition({customer: customer})
    }


    return(
        <ASelect
            onInputChange={onSearch}
            options={customers?customers:[]}
            label="Select customer"
           {...props}
        />
    )
}