import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE, 
    CONDITION_PARAM
} from '../../utils/graphql.config'

const GET_EXECUTIVES = gql`
query(${PAGINATION_PARAMS}){
    GetManyExecutiveUser(${PAGINATION_VALUES}){
        id
        authUser{
          id
          firstName
          mobile
        }
        executive_code
        status
        free_coins
        premium_coins
        rating
    }
    GetExecutiveUserCount(${CONDITION_VALUE})
}
`

const CREATE_EXECUTIVE = gql`
mutation($data: CreateIbapUserDto!){
    createExecutiveUser(data: $data){
      id
    }
}
`

const UPDATE_EXECUTIVE = gql`
mutation($data: UpdateIbapUserDto!){
    updateExecutiveUser(data: $data){
      id
    }
}
`

const GET_ONE_EXECUTIVE = gql`
query(${CONDITION_PARAM}){
    GetOneExecutiveUser(${CONDITION_VALUE}){
      id
      authUser{
        id
        firstName
        mobile
        password
      }
      executive_code
      status
      account_type
      call_cost
      profession
    }
}
`

const DELETE_EXECUTIVE = gql`
mutation($data: DeleteDto!){
    deleteExecutiveUser(data:$data){
      id
    }
}`






export {
    GET_EXECUTIVES,
    CREATE_EXECUTIVE,
    GET_ONE_EXECUTIVE,
    UPDATE_EXECUTIVE,
    DELETE_EXECUTIVE
}