import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import moment from 'moment'

import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage,
    ADateTime,
    AGrid,
    AButton
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import { GET_USER_SUBSCRIPTIONS} from './api';
import CustomerSelect from '../customers/CustomerSelect';

export default function Transaction(){

    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)

    const [customer, setCustomer] = useState(0)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)

    const [condition, setCondition] = useState({})
    const limit = 10 

    const { loading, error, data, refetch } = useQuery(GET_USER_SUBSCRIPTIONS, {
        variables: { skip:skip, limit:limit,  condition, sort:"-updatedAt"},
    });

    React.useEffect(() => {
        refetch()
    }, [skip])

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    const onFilter = ()=>{
        if(customer)
        setCondition({...condition, authUser: customer.value})
        if(startTime)setCondition({...condition, updatedAt:{___gte:startTime}})
        if(endTime)setCondition({...condition, updatedAt:{___lte:endTime}})
    }

    const onClear = ()=>{
        setCondition({})
    }

    const HeaderFilter = (
        <AGrid spacing={2} container className="search-header" direction="row"  alignItems="center">
            <AGrid item md={3}>
                <CustomerSelect value={customer} onChange={(e)=>setCustomer(e)}/>
            </AGrid>
            <AGrid item md={3}>
                <ADateTime value={startTime} onChange={(e)=>setStartTime(e)} type="both"/>
            </AGrid>
            <AGrid item md={3}>
                <ADateTime value={endTime} onChange={(e)=>setEndTime(e)} type="both"/>
            </AGrid>
            <AGrid item md={4} >
                <AButton  variant="contained"
                    style={{marginRight: 10}}
                    onClick={onClear}>RESET</AButton>
                <AButton  variant="contained"
                    color="default"
                    onClick={onFilter}
                    className="bg-btn mr">FILTER</AButton>
            </AGrid>
        </AGrid>
    )

    if(loading){
        return (
            <APage >
                <PageHeader export={false} add={false}>Purchase History</PageHeader>
                {HeaderFilter}
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.getManyUserSubscription || data.getManyUserSubscription.length < 1){
        return (
            <APage>
                 <PageHeader export={false} add={false}>Purchase History</PageHeader>
                 {HeaderFilter}
                <NoDataFound/>
            </APage>
        )
    } 

    const histories = data.getManyUserSubscription;
    const count = data.GetUserSubscriptionsCount;  

    return(
        <APage>
             <PageHeader export={false} add={false}>Purchase History</PageHeader>
             {HeaderFilter}
            <ATable>
                <ATableHead>
                <ATableRow> 
                        <ATableCell>Customer</ATableCell>
                        <ATableCell>Order ID</ATableCell>
                        <ATableCell>Plan</ATableCell>
                        <ATableCell>Amount</ATableCell>
                        <ATableCell>Transaction Status</ATableCell>
                        <ATableCell>Time</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {histories.map((item,index)=>(
                <ATableRow key={index}> 
                        <ATableCell> 
                            <p>{item.authUser.firstName}</p> 
                            <p>{item.authUser.mobile}</p>
                        </ATableCell>
                        <ATableCell> <span>{item.order_id}</span> </ATableCell>
                        <ATableCell> {item.plan.name}</ATableCell>
                        <ATableCell> Rs {item.price} </ATableCell>
                        <ATableCell> <span style={{color: item.payment_status=='created'?'orange':'green'}}>{item.payment_status}</span> </ATableCell>
                        <ATableCell> {moment(item.updatedAt).format('DD-MM-YYYY hh:mm a')}</ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}