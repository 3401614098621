import React from 'react';
import * as Constant from '../utils/AppConstants';
import { CallStatus } from './constants';

function getRandomId(length=8){
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function tConvert (time, a=true) {
  if(!time) return ''
  const timeString = time
  // // Check correct time format and split into components
  // time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) { // If time format correct
  //     if(time[4])time.pop()
  //     time = time.slice (1);  // Remove full string match value
      
  //     a?time[5] = time[0] < 12 ? ' AM' : ' PM':''; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  }else if(time){
      var times = timeString.split(':')
      if(times && times.length > 1){
          var hr = times[0]
          var mn = times[1]
          var a = 'AM'
          if(hr >= 12){
              a = 'PM'
              if(hr>12)hr = hr-12
          }
          if(hr<10){
              hr = '0'+parseInt(hr)
          }
          if(mn<10){
              mn = '0'+parseInt(mn)
          }
          const t = hr+':'+mn + ' '+a
          return t
      }
  }
  return time.join (''); // return adjusted time or original string
}

function formatDate(date, only_date=false, reverse=false){
  var d = new Date(date)
  d.setHours(0, 0, 0, 0);
  const today = new Date()
  today.setHours(0, 0, 0, 0);
  const lat = today.toString()
  const yesterday = new Date(lat)
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(lat)
  tomorrow.setDate(tomorrow.getDate() + 1);
  if(!only_date){
      if(d.getTime()==today.getTime()) return "Today"
      if(d.getTime()==yesterday.getTime()) return "Yesterday"
      if(d.getTime()==tomorrow.getTime())return "Tommrrow"
  }
  var dd = d.getDate()<10?'0'+d.getDate():d.getDate()
  var dm = (d.getMonth()+1)<10?'0'+(d.getMonth()+1):d.getMonth()+1
  if(reverse) return d.getFullYear()+'-'+dm+'-'+dd
  return  dd+ '-' + dm + '-' +d.getFullYear()
}

function getColorForCallStatus(callStatus){

  switch(callStatus){
    case CallStatus.INITIATED:
      return "grey"
    case CallStatus.RINGING:
      return "orange"
    case CallStatus.IN_PROGESS:
      return "purple"
    case CallStatus.COMPLETED:
      return "green"
    case CallStatus.BUSY:
      return "coral"
    case CallStatus.FAILED:
      return "red"
    case CallStatus.CANCELED:
      return "brown"
    case CallStatus.NO_ANSWER:
      return "red"
  }
}

function getColorForOnlineStatus(status){

  switch(status){
    case "Offline":
      return "grey"
    case "Busy":
      return "orange"
  }
  return "green"
}

export {
  getRandomId,
  tConvert,
  formatDate,
  getColorForCallStatus,
  getColorForOnlineStatus
}