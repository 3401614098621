import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import theme from './theme'
import GlobalStyles from './theme/GlobalStyles';
import { URL_PREFIX } from './utils/constants';

function App() {
  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles/>
      {routing}
    </ThemeProvider>
  );
}

export default App;