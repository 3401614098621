import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
   Card,
   CardHeader,
   CardContent,
   Grid,
} from '@material-ui/core'; 
import { 
   APage,
   AGrid,
   ALoader,
   ATableCell,
   ATable,
   ATableHead,
   ATableRow,
   ATableBody
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import {
    GET_CALL_DETAILS
} from './api'
import moment from 'moment';

const CallHistoryDetailPage = () =>{

    const { id, sid } = useParams()
   
   const { loading:getLoading, data } = useQuery(
      GET_CALL_DETAILS, 
      { 
         variables: {id: id, executive: sid.split("_")[1], sid },
         skip: (!sid || !id)
      }
   );

   const item = (title, value, color='black')=>{
        return <AGrid container spacing={2}>
            <AGrid item md={6}>
                <span style={{color}}>{title}</span>
            </AGrid>
            <AGrid item md={6}>
                <span style={{color}}>:  {value}</span>
            </AGrid>
        </AGrid>
   }

   if(getLoading){
     return <ALoader/>
   }

   if(!data) return null
   var details = data.getOneCallHistory
   var executive = data.GetOneExecutiveUser
   var logs = data.getManyCallLogsHistories
   if(!executive || !details) return null

   return(
      <APage>
         <PageHeader>Call Details</PageHeader>
         <AGrid container spacing={2} className="page-details">
            <AGrid item md={12}> 
               <Card className="search-header page-form">
                     <CardHeader title="Call Info" />
                     <CardContent>
                        {item("SID", details.inbound_call_sid)}
                        {item("Executive", details.executive.firstName)}
                        {item("Customer", details.customer.firstName)}
                        {item("Status", details.call_status)}
                        {item("Duration(sec)", details.call_duration)}
                        {item("Free Coins", details.free_coins_used)}
                        {item("Premium Coins", details.coins_used)}
                        {item("Total Coins", details.free_coins_used + details.coins_used)}
                        {item("Rating", details.rating?details.rating:"Not Rated")}
                     </CardContent>
               </Card>
               <AGrid container spacing={2}>
                    <AGrid item md={6}>
                        <Card className="search-header page-form">
                            <CardHeader title="Customer Info" />
                            <CardContent>
                                {item("Name", details.customer.firstName)}
                                {item("Code", details.inbound_call_sid.split("_")[0])}
                                {item("Initial Free Coins", details.initial_customer_coin?.free_coins)}
                                {item("Initial Premium Coins", details.initial_customer_coin?.premium_coins)}
                                {item("Total Coins Before Call", details.initial_customer_coin?.total_coins, 'orange')}
                                {item("Final Free Coins", details.final_customer_coin?.free_coins)}
                                {item("Final Premium Coins", details.final_customer_coin?.premium_coins)}
                                {item("Final Coins After Call", details.final_customer_coin?.total_coins, 'green')}
                            </CardContent>
                        </Card>
                    </AGrid>
                    <AGrid item md={6}>
                        <Card className="search-header page-form">
                            <CardHeader title="Executive Info" />
                            <CardContent>
                                {item("Name", details.executive.firstName)}
                                {item("Code", details.inbound_call_sid.split("_")[1])}
                                {item("Coins/Minute", executive.call_cost)}
                                {item("Initial Free Coins", details.initial_executive_coin?.free_coins)}
                                {item("Initial Premium Coins", details.initial_executive_coin?.premium_coins)}
                                {item("Total Coins Before Call", details.initial_executive_coin?.total_coins, 'orange')}
                                {item("Final Free Coins", details.final_executive_coin?.free_coins)}
                                {item("Final Premium Coins", details.final_executive_coin?.premium_coins)}
                                {item("Final Coins After Call", details.final_executive_coin?.total_coins, 'green')}
                            </CardContent>
                        </Card>
                    </AGrid>
                </AGrid>  
               {logs && <Card className="search-header page-form">
                     <CardHeader title="Event Logs" />
                     <CardContent>
                        <ATable>
                            <ATableHead>
                                <ATableRow> 
                                    <ATableCell>Event</ATableCell>
                                    <ATableCell>Message</ATableCell>
                                    <ATableCell>Origin</ATableCell>
                                    <ATableCell>Time</ATableCell>
                                    <ATableCell>Duration</ATableCell>
                                </ATableRow>    
                            </ATableHead>
                            <ATableBody>
                            {logs.map((log, index)=>{
                                if(!log || !log.event_data) return null
                            return <ATableRow key={index}> 
                                <ATableCell><p>{log.event_data.status}</p> </ATableCell>
                                <ATableCell><p>{log.event_data.message}</p></ATableCell>
                                <ATableCell> {log.event_data.userType} </ATableCell>
                                <ATableCell> {moment(log.createdAt).format('DD-MM-YYYY hh:mm:ss a')}</ATableCell>
                                <ATableCell> {log.event_data.callDuration} </ATableCell>
                            </ATableRow> 
                            })}   
                            </ATableBody>
                        </ATable>
                     </CardContent>
               </Card>}            
            </AGrid> 
        </AGrid>
      </APage>   
   )
}
export default CallHistoryDetailPage;