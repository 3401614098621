import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'


const CREATE_FREE_PLAN = gql`
mutation($data: CreateFreePlanDto!){
    createFreePlan(data: $data){
      id
    }
  }
`

const GET_FREE_PLANS = gql`
query{
    getManyFreePlans{
      id
      total_coins
      number_of_days
      max_coin_per_day
    }
  }
`

export {
    CREATE_FREE_PLAN,
    GET_FREE_PLANS
}