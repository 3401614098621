import React, {Component} from 'react'
import Dropzone from 'react-dropzone'
import placeholder from '../../theme/images/placeholder.jpg'

function isFile(file) {
  if (file instanceof File){
      return true;
  }
  return false;
}


class ADropzone extends Component{

    constructor(props){
        super(props)

        this.state = {
            image : this.props.src
        }
    }

    handleDrop = (e)=>{
        this.setState({
            image:  URL.createObjectURL(e[0])
        })
        this.props.onDrop(e)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(!prevState.image && nextProps.src){
            const image = isFile(nextProps.src)?URL.createObjectURL(nextProps.src):nextProps.src
            return {...prevState, image}
        }
        if(prevState.image && nextProps.src == null){
            return {...prevState, image:nextProps.src}
        } 
        return {...prevState}
    }

    render(){
        const {image} = this.state
        const {validator, name, rules, message, accept, ...props} = this.props

        return (
        <div className="wrapper-field">
            {(!props.disableDeleteIcon && image) && 
                <a className="card-delete-icon" 
                    style={{
                        position: 'relative',
                        float: 'right',
                        marginRight: -15,
                        marginTop: -26
                    }}
                    onClick={props.onDelete}>
                        <i className="window minimize outline icon"></i>
                </a>
            }
            <Dropzone    
                className='dropzone'
                activeClassName='active-dropzone'
                multiple={props.multiple}
                accept={accept?accept:"image/jpeg,image/jpg,image/tiff,image/gif"}
                {...props}
                onDrop={ this.handleDrop } >
            
            {({getRootProps, getInputProps}) => (
                <section className="drop-wrapper">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    { image ?
                        (accept && accept.includes('csv'))?
                        <img src={ placeholder } alt="image preview" className="drop-image"/>:
                        <img src={ image } alt="image preview" className="drop-image"/>:
                        <p className="drop-txt">{message?message:"Drag 'n' drop image or click here"}</p>
                    }
                </div>
                </section>
            )}
            </Dropzone>
            {validator? validator.message(name, image, rules):null}
        </div>
        )
        
    }
}

ADropzone.defaultProps = {
    disableDeleteIcon: true
}

export default ADropzone