export const RESOURCE_TYPES = {
    all:'All',
    primary:'Primary',
    seconday:'Secondary', 
    tertiary:'Tertiary'
}

export const RESOURCE_TYPE_OPTIONS = Object.values(RESOURCE_TYPES).map((v,i)=>{ return {label:v, value:v}})

export const WEEK_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export const PERMISSIONS = [
    "Dashboard",
    "Order",
    "Invoice",
    "Subscription",
    "Coupon",
    "Chat",
    "Feedback",
    "Notification",
    "Tanker",
    "Driver",
    "Fuel",
    "Vehicle",
    "Customer",
    "User",
    "Settings",
    "Report",
    "Log",
    "Backup"
]

export const PERMS = {
    Dashboard:'Dashboard',
    Order:'Order',
    Invoice:'Invoice',
    Subscription:'Subscription',
    Coupon:'Coupon',
    Chat:'Chat',
    Feedback:"Feedback",
    Notification:'Notification',
    Tanker:'Tanker',
    Driver:'Driver',
    Fuel:"Fuel",
    Vehicle:'Vehicle',
    Customer:'Customer',
    User:'User',
    Settings:'Settings',
    Report:'Report',
    Log:'Log',
    Backup:'Backup',
}






