import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'

const CREATE_APP_VERSIONS = gql`
mutation($data: CreateAppVersionDto!){
    createAppVersion(data:$data){
      id
    }
  }
`

const GET_MANY_APP_VERSIONS = gql`
query(${PAGINATION_PARAMS}){
    getManyAppVersions(${PAGINATION_VALUES}){
      id
      version
      type
      message
      updation
    }
    GetAppVersionCount
}
`

export{
    CREATE_APP_VERSIONS,
    GET_MANY_APP_VERSIONS
}