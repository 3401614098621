import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
   Card,
   CardHeader,
   CardContent,
} from '@material-ui/core'; 
import { 
   APage,
   AGrid,
   AInput,  
   ATextArea,
   AButton,
   AForm,
   ALoader
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import {
    GET_PLAN,
   UPDATE_PLAN,
   ADD_PLAN
} from './api'
import { 
   MUTATION_CALL_BACK,
   QUERY_CALL_BACK
} from '../../utils/graphql.config'

const SubscriptionForm = () =>{

   const params = useParams()
   const [name, setName] = useState('')
   const [description, setDescription] = useState('')
   const [price, setPrice] = useState('')
   const [gst, setGst] = useState('18')
   const [number_of_coins, setNumberOfCoins] = useState('')

   var id = null
   if(params && params.id) id = params.id 

   const SuccessMessage = 'Subscription plan saved successfully'
   const ErrorMessage = 'Subscription plan saving failed'

   const { register, handleSubmit, errors } = useForm(); 
   const mutationCallBack = (success)=>{
      if(success && !id){
         setName('')
         setPrice('')
         setDescription('')
         setNumberOfCoins('')
      }
   }

   const queryCallBack = (success, data)=>{
      if(success){
         const obj = data.getOneSubscriptionPlan
         if(obj){
            setName(obj.name)
            setPrice(obj.price)
            setDescription(obj.description)
            setNumberOfCoins(obj.number_of_coins)
            setGst(obj.gst)
         }
      }
   }

   const [addPlan, { loading }] = useMutation(ADD_PLAN, 
   MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

   const [ updatePlan, { loading:updateLoading }] = useMutation(UPDATE_PLAN, 
   MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

   const { loading:getLoading } = useQuery(
      GET_PLAN, 
      { 
         variables: {condition: {_id: id} }, 
         skip: id?false:true,
         ...QUERY_CALL_BACK(queryCallBack)
      }
   );

   const onSubmit = (data)=>{
      var data = {
         ...data, 
         
         price: parseFloat(data.price),
         number_of_coins: parseInt(data.number_of_coins),
         gst: parseFloat(data.gst),
      }
      if(id){
         onUpdate({...data, id})
      }else{
         onSave({...data})
      }
   }

   const onSave = (data)=>{
      addPlan({ 
         variables: { data }
      })
   }

   const onUpdate = (data)=>{
      updatePlan({ 
         variables: { data }
      })
   }

   if(loading || getLoading || updateLoading){
      return <ALoader/>
   }

   return(
      <APage>
         <PageHeader>Add Subscription Plan</PageHeader>
         <AGrid container spacing={2} className="page-details">
            <AGrid item md={12}> 
               <Card className="search-header page-form">
                     <CardHeader title="Subscription Info" />
                     <CardContent>
                     <AForm onSubmit={handleSubmit(onSubmit)}>
                     <AGrid container spacing={2}>
                        <AGrid item md={6}>
                        <AInput 
                              label="Subscription Name"
                              name="name"
                              errors={errors}
                              value={name}
                              onChange={(e)=>setName(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                        <AInput 
                              label="Price"
                              name="price"
                              errors={errors}
                              value={price}
                              onChange={(e)=>setPrice(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                        <AInput 
                              label="GST"
                              name="gst"
                              errors={errors}
                              value={gst}
                              onChange={(e)=>setGst(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                           <AInput 
                              label="Number of coins"
                              name="number_of_coins"
                              errors={errors}
                              value={number_of_coins}
                              onChange={(e)=>setNumberOfCoins(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={12}>
                           <AButton type="submit" variant="contained"
                           color="default"
                           className="bg-btn mr">SAVE</AButton>
                        </AGrid>
                     </AGrid>
                     </AForm>
                     </CardContent>
               </Card>           
            </AGrid> 
        </AGrid>
      </APage>   
   )
}
export default SubscriptionForm;