import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

function AInput({
    label, 
    placeholder, 
    name, 
    errors,
    value, 
    message,
    register,
    rules,
    ...props
}){

    var holder = 'Enter here'
    if(label) holder = label
    if(placeholder) holder = placeholder
    if(name) holder = name
    var error = false
    if((errors && errors[name])) error = true


    return ( 
        <>
            <TextField 
                id="outlined-helperText"
                label={label}
                value={value} 
                variant="outlined"
                name={name}
                error={error}
                inputRef={register?register(rules):null}
                // helperText={error?message:null}
                {...props}
            />
            {/* {(errors && errors[name])? message: null} */}
        </>
     );
}

AInput.propTypes = {
    name : PropTypes.string.isRequired
}

AInput.defaultProps = {
    value : '',
    name:'input',
    message: "Field is invalid",
    errors:{},
    rules:{}
}

 
export default AInput;