import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'

const GET_APPUSERS = gql`
query(${PAGINATION_PARAMS}){
    GetManyAppUser(${PAGINATION_VALUES}){
      id
      authUser{
        id
        firstName
        mobile
        isActive
      }
      customer_code
    }
    GetAppUserCount(${CONDITION_VALUE})
}
`

const UPDATE_WALLET = gql`
mutation($data: UpdateUserWalletByAdminDto!){
  updateUserSubscriptionByAdmin(data:$data){
    id
  }
}
`

const GET_USER_WALLET = gql`
query($condition:JSONObject){
  getOneUserWallet(condition:$condition){
    id
    total_coins
    used_coins
  	authUser{
      id
      firstName
      mobile
      isActive
    }
  }
}
`

const GET_ONE_APP_USER = gql`
query($condition:JSONObject){
  GetOneAppUser(condition:$condition){
    id
  }
}
`

const UPDATE_USER_STATUS = gql`
mutation($data: UpdateAppUserDto!){
  updateAppUser(data: $data){
    id
  }
}

`

export {
    GET_APPUSERS,
    UPDATE_WALLET,
    GET_USER_WALLET,
    UPDATE_USER_STATUS,
    GET_ONE_APP_USER
}