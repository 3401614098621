import React from 'react'
import { 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination
} from '@material-ui/core';

function ATable({
    children,
    ...props
}){

    return <Table {...props} className="table-wrapper">{children}</Table>
} 

function ATableBody({
    children,
    ...props
}){

    return <TableBody {...props}>{children}</TableBody>
} 

function ATableRow({
    children,
    ...props
}){

    return <TableRow {...props}>{children}</TableRow>
} 
function ATableCell({
    children,
    ...props
}){

    return <TableCell {...props}>{children}</TableCell>
} 

function ATableHead({
    children,
    ...props
}){

    return <TableHead {...props}>{children}</TableHead>
} 

function ATableFooter({
    children,
    ...props
}){

    return <TableFooter {...props}>{children}</TableFooter>
} 

function ATablePagination({
    children,
    ...props
}){

    return(
        <ATableFooter>
            <ATableRow>
                <TablePagination
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    rowsPerPageOptions={[10]}
                    // count={rows.length}
                    // rowsPerPage={rowsPerPage}
                    // page={page}
                    // SelectProps={{
                    // inputProps: { 'aria-label': 'rows per page' },
                    // native: true,
                    // }}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    {...props}
                />
            </ATableRow>
        </ATableFooter>
    ) 
} 

export{
    ATable,
    ATableBody,
    ATableCell,
    ATableHead,
    ATableRow,
    ATableFooter,
    ATablePagination
}