import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

import { 
    APage,
    AGrid,
    AInput,  
    ATextArea,
    AButton,
    AForm,
    ALoader
 } from '../../components/basic';
 import {
    Card,
    CardHeader,
    CardContent,
 } from '@material-ui/core'; 
import PageHeader from '../../components/common/PageHeader'
import { MUTATION_CALL_BACK, QUERY_CALL_BACK } from '../../utils/graphql.config'
import { CREATE_FREE_PLAN, GET_FREE_PLANS,  } from './api'

export default function About(){


    const SuccessMessage = 'Free plan saved successfully'
    const ErrorMessage = 'Free plan saving failed'
    const { register, handleSubmit, errors } = useForm(); 
    const [ plan, setPlan ] = useState('')
    const [total_coins, setTotalCoins] = useState('')
    const [number_of_days, setNumberOfDays] = useState('')
    const [max_coin_per_day, setMaxCoinsPerDay] = useState('')
    const [object, setObject] = useState(null)

    const mutationCallBack = (success)=>{
      
    }
 
    const queryCallBack = (success, data)=>{
        if(success && data){
            const obj = data.getManyFreePlans
            if(obj.length > 0){
                const p = obj[0]
                setPlan(p)
                setTotalCoins(p.total_coins)
                setNumberOfDays(p.number_of_days)
                setMaxCoinsPerDay(p.max_coin_per_day)
            }
        }
    }
 
    const [updateFreePlan, { loading }] = useMutation(CREATE_FREE_PLAN, 
    MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));
 
    const { loading:getLoading } = useQuery(
        GET_FREE_PLANS, 
       { 
          variables: { }, 
          ...QUERY_CALL_BACK(queryCallBack)
       }
    );

    const onSubmit = ()=>{
        const d = {
            name:"Free new user",
            total_coins: parseFloat(total_coins),
            number_of_days: parseFloat(number_of_days),
            max_coin_per_day: parseFloat(max_coin_per_day),
            user_level:"New"
         }
 
        updateFreePlan({
            variables:{
                data:d
            }
        })
    }

    if(loading || getLoading){
        return <ALoader/>
    }

    return(
        <APage>
           <PageHeader>Free Plan</PageHeader>
           <AGrid container spacing={2} className="page-details">
              <AGrid item md={12}> 
                 <Card className="search-header page-form">
                       <CardHeader title="Free Plan Info" />
                       <CardContent>
                       <AForm onSubmit={handleSubmit(onSubmit)}>
                       <AGrid container spacing={2}>
                          <AGrid item md={6}>
                          <AInput 
                                label="Total Number Of Coins"
                                name="total_coins"
                                errors={errors}
                                value={total_coins}
                                onChange={(e)=>setTotalCoins(e.target.value)}
                                register={register}
                                rules={{ required: true }}/>
                          </AGrid>
                          <AGrid item md={6}>
                          <AInput 
                                label="Coin For Total Days"
                                name="number_of_days"
                                errors={errors}
                                value={number_of_days}
                                onChange={(e)=>setNumberOfDays(e.target.value)}
                                register={register}
                                rules={{ required: true }}/>
                          </AGrid>
                          <AGrid item md={6}>
                             <AInput 
                                label="Coins Per Day"
                                name="max_coin_per_day"
                                errors={errors}
                                value={max_coin_per_day}
                                onChange={(e)=>setMaxCoinsPerDay(e.target.value)}
                                register={register}
                                rules={{ required: true }}/>
                          </AGrid>
                          <AGrid item md={12}>
                             <AButton type="submit" variant="contained"
                             color="default"
                             className="bg-btn mr">SAVE</AButton>
                          </AGrid>
                       </AGrid>
                       </AForm>
                       </CardContent>
                 </Card>           
              </AGrid> 
          </AGrid>
        </APage> 
    )
}