import React from 'react'

export default function NoDataFound(){
    return (
        <div className="nofound">
            <h3>
            Not data Found
            </h3>
        </div>
    )
}