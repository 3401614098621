import React from 'react'
import { AppBar, Toolbar } from '@material-ui/core';

function AToolbar({
    children,
    ...props
}){

    return <Toolbar {...props}>{children}</Toolbar>
} 

function AAppBar({
    children,
    ...props
}){

    return <AppBar {...props}>{children}</AppBar>
} 

export{
    AToolbar,
    AAppBar 
}