import { useQuery } from '@apollo/client';
import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage,
    AInput,
    AGrid,
    AButton
} from '../../components/basic';
import { URL_PREFIX } from '../../utils/constants';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import { GET_APPUSERS } from './api';
import { IconButton } from '@material-ui/core';

const PREFIX = '/'+URL_PREFIX.ADMIN

export default function Customers(){

    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const [condition, setCondition] = useState({})
    const [ search, setSearch ] = useState("")
    const [ code, setCode ] = useState("")
    const limit = 10 

    const { loading, error, data, refetch } = useQuery(GET_APPUSERS, {
        variables: { skip:skip, limit:limit,  condition},
    });

    React.useEffect(() => {
        refetch()
    }, [skip])

    React.useEffect(() => {
        refetch()
    }, [condition])

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    const onFilter = ()=>{
        var filter = {}
        if(search){
            const customer = {___regex: ".*"+search+'.*', ___options : 'i'}
            filter['mobile'] = customer
        }
        if(code){
            const customer_code = {___regex: ".*"+code+'.*', ___options : 'i'}
            filter['customer_code'] = customer_code
        }
        
        setCondition(filter)
    }
    
    if(loading){
        return (
            <APage>
                <PageHeader export={false} add={false}>Customers</PageHeader>
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.GetManyAppUser || data.GetManyAppUser.length < 1){
        return (
            <APage>
                <PageHeader export={false} add={false}>Customers</PageHeader>
                <NoDataFound/>
            </APage>
        )
    } 

    const customers = data.GetManyAppUser;
    const count = data.GetAppUserCount;  

    return(
        <APage>
            <PageHeader export={false} add={false} >Customers</PageHeader>
            <AGrid spacing={2} container className="search-header" direction="row"  alignItems="center">
                <AGrid item md={6}>
                    <AInput 
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                        label="Mobile Number"/>
                </AGrid>
                <AGrid item md={6}>
                    <AInput 
                        value={code}
                        onChange={(e)=>setCode(e.target.value)}
                        label="Customer Code"/>
                </AGrid>
                <AGrid item md={4} >
                    <AButton  variant="contained"
                    color="default"
                    onClick={onFilter}
                    className="bg-btn mr">FILTER</AButton>
                </AGrid>
            </AGrid>
            <ATable>
                <ATableHead>
                <ATableRow> 
                        <ATableCell>Name</ATableCell>
                        <ATableCell>Mobile</ATableCell>
                        <ATableCell>Customer Code</ATableCell>
                        <ATableCell>Status</ATableCell>
                        <ATableCell>Action</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {customers.map((item,index)=>(
                <ATableRow key={index}> 
                        <ATableCell> {item.authUser.firstName} </ATableCell>
                        <ATableCell> {item.authUser.mobile} </ATableCell>
                        <ATableCell> {item.customer_code} </ATableCell>
                        <ATableCell> {item.authUser.isActive?
                            <span style={{color:'green'}}>Active</span>:
                            <span style={{color:'red'}}>Blocked</span>} </ATableCell>
                        <ATableCell> 
                            <div className='group-actions'>
                                <IconButton aria-label="edit" component={Link} to={PREFIX+"/update-wallet/"+item.authUser.id}> <EditIcon /></IconButton>
                            </div>
                        </ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}