import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Page = forwardRef(({ children,classNew,title = '', ...rest}, ref) => {
  const menuVis = classNew ? classNew : '';
  return (
    <div
      ref={ref}
      {...rest}
      className={`main-page-wrapper ${menuVis}`}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
