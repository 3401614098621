import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#00AEA9'
    },
    secondary: {
      main: colors.teal[400]
    },
    text: {
      primary: '#243645',
      secondary: '#7D7D7D'
    }
  },
  shadows
});

export default theme;
