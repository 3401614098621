import React from 'react'
import { Grid } from '@material-ui/core';

export default function AGrid({
    children,
    style,
    ...props
}){

    return(
        <Grid style={style} {...props}>
            {children}
        </Grid>
    )  
} 