import React from 'react';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context';
import { BASE_URL } from './index';

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

var token = 'guest'
const saved_token = localStorage.getItem('callmatez_admin_token')
if(saved_token){
  token = saved_token
}

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: 'bearer '+token
    }
  }
});

const httpLink = createUploadLink({
  uri: BASE_URL,
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions
});

// console.log(token)

// const client = new ApolloClient({
//   uri: BASE_URL,
//   cache: new InMemoryCache(),
//   // credentials: 'include',
//   headers: {
//     // authorization: 'Bearer '+localStorage.getItem('token') || null,
//     authorization:"bearer "+token
//     // 'client-name': 'WidgetX Ecom [web]',
//     // 'client-version': '1.0.0'
//   },
//   defaultOptions
// });

export default client