
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';

function ADateTime({
    type, 
    onChange, 
    value, 
    label, 
    style, 
    ...props
}){
    if(type === 'date'){
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker 
                    value={value} 
                    onChange={onChange} 
                    label={label}
                    inputVariant="outlined" 
                    format="MM/dd/yyyy"/>
            </MuiPickersUtilsProvider>
        ) 
      }
  
      if(type === 'time'){
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    value={value} 
                    id="time-picker"
                    onChange={onChange} 
                    label={label}
                    inputVariant="outlined" />
            </MuiPickersUtilsProvider>
        )     
      }
  
      if(type === 'both'){
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker 
                    value={value} 
                    onChange={onChange} 
                    label={label}
                    inputVariant="outlined"/>
            </MuiPickersUtilsProvider>
        )   
      }

}

ADateTime.defaultProps={
  type:'date',
  value: moment()
}

export default ADateTime