import React from 'react'

function NotFound({

}){
    return(
        <React.Fragment>
        <div className="nofound">
        <h3>
        Not data Found
        </h3>
        </div>
        </React.Fragment>
    )
}

export default NotFound