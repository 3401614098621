import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
class ATableActionCell extends Component {
    state = {  }
    render() { 
        return ( 
            <div className='group-actions'>
                {this.props.view ? <IconButton aria-label="visible" onClick={this.props.viewClick}> <VisibilityIcon /> </IconButton> : null}
                {this.props.edit ?<IconButton aria-label="edit" onClick={this.props.editClick}> <EditIcon /> </IconButton> : null}
                {this.props.delete ?<IconButton aria-label="delete" onClick={this.props.deleteClick}> <DeleteIcon /> </IconButton> : null}
            </div>
         );
    }
}

ATableActionCell.defaultProps = {
    delete : true,
    edit: true,
    view: false
}
 
export default ATableActionCell;