const TaskRouter = require('twilio-taskrouter');
const Twilio = require('twilio');
const AccessToken = Twilio.jwt.AccessToken;
const TaskRouterGrant = AccessToken.TaskRouterGrant;
const VoiceGrant = AccessToken.VoiceGrant;


const config = require('./config.js');

const accountSid = config.accountSid;
const signingKeySid = config.apiKey;
const signingKeySecret = config.apiSecret;
const workspaceSid = config.workspaceId;
const workerSid = config.workerId;

const token = createAccessToken(accountSid, signingKeySid, signingKeySecret, workspaceSid, workerSid);
export default token
// const alice = new TaskRouter.Worker(token);

// alice.on('ready', readyAlice => {
//     console.log(`Worker ${readyAlice.sid} is now ready for work`);
// });

// alice.on('reservationCreated', reservation => {
//     console.log(`Reservation ${reservation.sid} has been created for ${alice.sid}`);
//     console.log(`Task attributes are: ${reservation.task.attributes}`);

//     reservation.on('accepted', acceptedReservation => {
//       console.log(`Reservation ${acceptedReservation.sid} was accepted.`);
//     });

//     reservation.accept().then(acceptedReservation => {
//       console.log(`Reservation status is ${acceptedReservation.status}`);
//     }).catch((err) => {
//       console.log(`Error: ${err}`);
//     });
// });

function createAccessToken(accountSid, signingKeySid, signingKeySecret, workspaceSid, workerSid) {
    const taskRouterGrant = new TaskRouterGrant({
        workerSid: workerSid,
        workspaceSid: workspaceSid,
        role: 'worker'
    });

    const grant = new VoiceGrant({
        outgoingApplicationSid: config.twimlAppSid,
        incomingAllow: true,
    });
    

    const accessToken = new AccessToken(accountSid, signingKeySid, signingKeySecret);
    accessToken.addGrant(taskRouterGrant);
    accessToken.addGrant(grant);
    accessToken.identity = 'TEXE1';

    return accessToken.toJwt();
}