export const URL_PREFIX = {
    ADMIN: '',
    BASE_URL:''
}

export const CURRENCY_SYMPOL = 'SAR';

export const GENDER_OPTIONS = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
 ];

export const CallStatus = {
    INITIATED:'initiated',
    RINGING:'ringing',
    IN_PROGESS:'in-progress',
    COMPLETED:'completed',
    BUSY:'busy',
    NO_ANSWER:'no-answer',
    CANCELED:'canceled',
    FAILED:'failed'
}
