import swal from 'sweetalert';

export const CONDITION_PARAM = `$condition:JSONObject`
export const CONDITION_VALUE = `condition:$condition`
export const PAGINATION_PARAMS = `$sort:String, $skip:Int, $limit:Int, ${CONDITION_PARAM}`
export const PAGINATION_VALUES = `sort:$sort, skip:$skip, limit:$limit, ${CONDITION_VALUE}`

export function MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, callBack){
    return {
        onCompleted(data){
            swal("Success!", SuccessMessage, "success");
            if(callBack) callBack(true, data)
        }, 
        onError(error){ 
            swal("Failed!", ErrorMessage, "error");
            if(callBack) callBack(false, error)
        }
    }
}

export function QUERY_CALL_BACK(callBack){
    return {
        onCompleted(data){
            if(callBack) callBack(true, data)
        }, 
        onError(error){ 
            if(callBack) callBack(false, error)
        }
    }
}

export function isEditForm(path){
    return path.includes("edit")
}