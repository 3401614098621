import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';


class ATextArea extends Component {
    render() { 
        const {label, placeholder, name, validator,error,value,register,rules,...props} = this.props
        return ( 
            <div noValidate autoComplete="off" className="input-container">
                <TextField 
                    id="standard-basic" 
                    label={label?label:placeholder?placeholder:name?name:'Enter'} 
                    variant="outlined"
                    multiline
                    rows="4"
                    value={value?value:''}
                    name={name?name:'input'}
                    error={error}
                    inputRef={register?register(rules):null}
                    {...props}
                />
                {validator? validator.message(name, props.value, rules):null}
            </div>
            
         );
    }
}

ATextArea.propTypes = {
    name : PropTypes.string.isRequired
}

ATextArea.defaultProps = {
    value : ''
}

 
export default ATextArea;