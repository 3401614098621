import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage
} from '../../components/basic';
import { URL_PREFIX } from '../../utils/constants';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import { DELETE_PLAN, GET_PLANS } from './api';
import { MUTATION_CALL_BACK } from '../../utils/graphql.config';
const PREFIX = '/'+URL_PREFIX.ADMIN

export default function Subscriptions(){


    const SuccessMessage = 'Subscription deleted successfully'
    const ErrorMessage = 'Subscription delete failed'
    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const [condition, setCondition] = useState({price: { ___gte:0}})
    const limit = 10 

    const { loading, error, data, refetch } = useQuery(GET_PLANS, {
        variables: { skip:skip, limit:limit,  condition},
    });

    React.useEffect(() => {
        refetch()
    }, [skip])

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    const mutationCallBack = (success)=>{
        if(success ){
            refetch()
        }
    }

    const [ deleteSubscription, { loading: deleteLoading }] = useMutation(DELETE_PLAN, 
        MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

    const onDelete = (id)=>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able use it!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                deleteSubscription({ 
                    variables: { data:{ id }}
                })
            } else {
              
            }
          });
    }

    if(loading || deleteLoading){
        return (
            <APage >
                <PageHeader export={false} add={true} to={PREFIX+"/add-plan"}>Plans</PageHeader>
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.getManySubscriptionPlans || data.getManySubscriptionPlans.length < 1){
        return (
            <APage>
                <PageHeader export={false} add={true} to={PREFIX+"/add-plan"}>Plans</PageHeader>
                <NoDataFound/>
            </APage>
        )
    } 

    const plans = data.getManySubscriptionPlans;
    const count = data.GetSubscriptionPlansCount;  

    return(
        <APage>
            <PageHeader export={false} add={true} to={PREFIX+"/add-plan"}>Plans</PageHeader>
            <ATable>
                <ATableHead>
                <ATableRow> 
                        <ATableCell>Name</ATableCell>
                        <ATableCell>Number of coins</ATableCell>
                        <ATableCell>Price</ATableCell>
                        <ATableCell>Actions</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {plans.map((item,index)=>(
                <ATableRow key={index}> 
                        <ATableCell> {item.name} </ATableCell>
                        <ATableCell> {item.number_of_coins} </ATableCell>
                        <ATableCell> {item.price} </ATableCell>
                        <ATableCell>
                            <div className='group-actions'>
                            <IconButton aria-label="edit" component={Link} to={PREFIX+"/edit-plan/"+item.id}> <EditIcon /></IconButton>
                            <IconButton aria-label="delete" onClick={()=>onDelete(item.id)}> <DeleteIcon /> </IconButton>
                            </div>
                        </ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}