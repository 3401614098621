import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { mapProps } from 'recompose';

import { 
    ASelect
} from '../../components/basic';
import { GET_EXECUTIVES } from './api';


export default function ExecutiveSelect(props){

    const [condition, setCondition] = useState({})
    const limit = 100
    const skip = 0

    const { loading, error, data, refetch } = useQuery(GET_EXECUTIVES, {
        variables: { skip:skip, limit:limit,  condition},
    });
    
    const customers = data?.GetManyExecutiveUser?.map((customer)=>{
        return {value: customer.id, label: customer.authUser.firstName, code: customer.executive_code}
    });

    useEffect(()=>{
        refetch()
    }, [condition])

    const onSearch = (search)=>{
        const customer = {___regex: ".*"+search+'.*', ___options : 'i'}
        setCondition({mobile: customer})
    }


    return(
        <ASelect
            onInputChange={onSearch}
            options={customers?customers:[]}
            label="Select executive"
           {...props}
        />
    )
}