
import { gql } from '@apollo/client';
import { 
    PAGINATION_PARAMS, 
    PAGINATION_VALUES,
    CONDITION_VALUE 
} from '../../utils/graphql.config'

const LOGIN = gql`
mutation($data: PasswordGrantDto!){
    login(data: $data){
        accessToken
        authUser{
            id
            firstName
            roles
        }
    }
}
`

export{
    LOGIN
}