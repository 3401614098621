import React from 'react'
import { ListItem, List } from '@material-ui/core';

function AList({
    children,
    ...props
}){

    return <List {...props}>{children}</List>
}

function AListItem({
    children,
    ...props
}){

    return <ListItem {...props}>{children}</ListItem>
} 

export{
    AListItem,
    AList
}