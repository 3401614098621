import React from 'react';
import logoUrl from '../../theme/images/round_logo.png';
const Logo = ({
    ...props
}) => {
  return <img
            alt="Logo"
            style={{width:200, height:200}}
            src={logoUrl}
            {...props}/>
};

export default Logo;