import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';


import { 
    ALoader,
    ATable, 
    ATableBody, 
    ATableRow,
    ATableCell, 
    ATableHead,
    ATablePagination,
    APage,
    AInput,
    AButton,
    AGrid
} from '../../components/basic';
import { URL_PREFIX } from '../../utils/constants';
import PageHeader from '../../components/common/PageHeader';
import NoDataFound from '../../components/common/NoDataFound';
import { DELETE_EXECUTIVE, GET_EXECUTIVES } from './api';
import { MUTATION_CALL_BACK } from '../../utils/graphql.config';
import { getColorForOnlineStatus } from '../../utils';
const PREFIX = '/'+URL_PREFIX.ADMIN

export default function Executives(){

    const SuccessMessage = 'Executive deleted successfully'
    const ErrorMessage = 'Executive delete failed'
    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const [condition, setCondition] = useState({})
    const [ search, setSearch ] = useState("")
    const [ code, setCode ] = useState("")
    const limit = 10 

    const { loading, error, data, refetch } = useQuery(GET_EXECUTIVES, {
        variables: { skip:skip, limit:limit,  condition},
    });

    React.useEffect(() => {
        refetch()
    }, [skip])

    React.useEffect(() => {
        refetch()
    }, [condition])

    const onFilter = ()=>{
        var filter = {}
        if(search){
            const customer = {___regex: ".*"+search+'.*', ___options : 'i'}
            filter['mobile'] = customer
        }
        if(code){
            const executive_code = {___regex: ".*"+code+'.*', ___options : 'i'}
            filter['executive_code'] = executive_code
        }
        
        setCondition(filter)
    }

    const onChangePage = (e, page)=>{
        setPage(page)
        setSkip(limit * page)
    }

    const mutationCallBack = (success)=>{
        if(success ){
            refetch()
        }
    }

    const [ deleteExecutive, { loading: deleteLoading }] = useMutation(DELETE_EXECUTIVE, 
        MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

    const onDelete = (id)=>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able use it!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                deleteExecutive({ 
                    variables: { data:{ id }}
                })
            } else {
              
            }
          });
    }

    if(loading || deleteLoading){
        return (
            <APage>
                <PageHeader export={false} add={false}>Executives</PageHeader>
                <ALoader/>
            </APage>
        )
    }

    if(error || !data || !data.GetManyExecutiveUser || data.GetManyExecutiveUser.length < 1){
        return (
            <APage>
                <PageHeader export={false} add={true} to={PREFIX+"/add-executive"}>Executives</PageHeader>
                <NoDataFound/>
            </APage>
        )
    } 

    const executives = data.GetManyExecutiveUser;
    const count = data.GetExecutiveUserCount;  

    return(
        <APage>
            <PageHeader export={false} add={true} to={PREFIX+"/add-executive"}>Executives</PageHeader>
            <AGrid spacing={2} container className="search-header" direction="row"  alignItems="center">
                {/* <AGrid item md={6}>
                    <AInput 
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                        label="Mobile Number"/>
                </AGrid> */}
                <AGrid item md={6}>
                    <AInput 
                        value={code}
                        onChange={(e)=>setCode(e.target.value)}
                        label="Executive Code"/>
                </AGrid>
                <AGrid item md={4} >
                    <AButton  variant="contained"
                    color="default"
                    onClick={onFilter}
                    className="bg-btn mr">FILTER</AButton>
                </AGrid>
            </AGrid>
            <ATable>
                <ATableHead>
                <ATableRow> 
                        <ATableCell>Name</ATableCell>
                        <ATableCell>Mobile</ATableCell>
                        <ATableCell>Executive Code</ATableCell>
                        <ATableCell>Free Coins</ATableCell>
                        <ATableCell>Premium Coins</ATableCell>
                        <ATableCell>Rating</ATableCell>
                        <ATableCell>Status</ATableCell>
                        <ATableCell>Actions</ATableCell>
                    </ATableRow>    
                </ATableHead>
                <ATableBody>

                {executives.map((item,index)=>(
                <ATableRow key={index}> 
                        <ATableCell> {item.authUser.firstName} </ATableCell>
                        <ATableCell> {item.authUser.mobile} </ATableCell>
                        <ATableCell> {item.executive_code} </ATableCell>
                        <ATableCell> {item.free_coins} </ATableCell>
                        <ATableCell> {item.premium_coins} </ATableCell>
                        <ATableCell> {item.rating} </ATableCell>
                        <ATableCell> <span style={{color: getColorForOnlineStatus(item.status)}}>{item.status}</span> </ATableCell>
                        <ATableCell>
                            <div className='group-actions'>
                            <IconButton aria-label="edit" component={Link} to={PREFIX+"/edit-executive/"+item.authUser.id}> <EditIcon /></IconButton>
                            <IconButton aria-label="delete" onClick={()=>onDelete(item.id)}> <DeleteIcon /> </IconButton>
                            </div>
                        </ATableCell>
                    </ATableRow> 
                ))}   
                </ATableBody>
                <ATablePagination 
                    count={count} 
                    page={page}
                    colSpan={8}
                    onChangePage={onChangePage}
                    rowsPerPage={limit}/>
            </ATable>
        </APage>
    )
}