import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { ALoader, APage } from '../../components/basic'
import AEditor from '../../components/basic/AEditor'
import PageHeader from '../../components/common/PageHeader'
import { MUTATION_CALL_BACK, QUERY_CALL_BACK } from '../../utils/graphql.config'
import { CREATE_OR_UPDATE_PAGE, GET_PAGE } from './api'

export default function About(){


    const SuccessMessage = 'About saved successfully'
    const ErrorMessage = 'About saving failed'
    const [data, setData] = useState('')
    const [object, setObject] = useState(null)

    const mutationCallBack = (success)=>{
      
    }
 
    const queryCallBack = (success, data)=>{
        if(success && data){
            const obj = data.getOneContactPage
            if(obj){
                setData(obj.message.about)
                setObject(obj)
            }
        }
    }
 
    const [updateAbout, { loading }] = useMutation(CREATE_OR_UPDATE_PAGE, 
    MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));
 
    const { loading:getLoading } = useQuery(
        GET_PAGE, 
       { 
          variables: {condition: {type: 'about'} }, 
          ...QUERY_CALL_BACK(queryCallBack)
       }
    );

    const onChange = (d)=>{
        setData(d)
    }

    const onSave = ()=>{
        var d = {
            type:'about',
            userType:'all',
            message:{
                about: data
            }
        }
        if(object && object.id){
            d['id'] = object.id
        }
        updateAbout({
            variables:{
                data:d
            }
        })
    }

    if(loading || getLoading){
        return <ALoader/>
    }

    return(
        <APage>
            <PageHeader export={false} save={true} onClick={onSave}>About</PageHeader>
            <AEditor data={data} onChange={onChange}/>
        </APage>
    )
}