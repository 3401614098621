import React, { useEffect, Children } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ABox,
  ADrawer,
  AHidden,
  AList,
  makeStyles
} from '../../../components/basic';
import {
  CreditCard as CreditCardIcon,
  PieChart as PieChartIcon,
  Gift as GiftIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Tag as TagIcon,
  MessageCircle as MessageCircleIcon,
  Bell as BellIcon,
  Star as StarIcon,
  User as UserIcon,
  Truck as TruckIcon,
  Droplet as DropletIcon,
  FileText as FileTextIcon,
  Clock as ClockIcon,
  DownloadCloud as CloudIcon
} from 'react-feather';
import NavItem from './NavItem';
import { URL_PREFIX } from '../../../utils/constants';
import { ReportBase } from 'istanbul-lib-report';
import { PERMISSIONS, PERMS } from '../../../utils/AppConstants';
import { connect } from 'react-redux';

const PREFIX = '/' + URL_PREFIX.ADMIN

const items = [
  {
    child:false,
    href: PREFIX + '/',
    icon: PieChartIcon,
    title: 'Dashboard',
    permission: PERMS.Dashboard
  },
  {
    child:false,
    href: PREFIX + '/executives',
    icon: StarIcon,
    title: 'Executives',
    permission: PERMS.Dashboard
  },
  {
    child:false,
    href: PREFIX + '/customers',
    icon: UserIcon,
    title: 'Customers',
    permission: PERMS.Dashboard
  },
  {
    child:true,
    title:'Report',
    icon: FileTextIcon,
    permission: PERMS.Dashboard,
    childrens :[
        {
          href: PREFIX + '/transactions',
          title: 'Purchase Report'
        },
        {
          href: PREFIX + '/caller-history',
          title: 'Caller History'
        }
      ],
  },
  {
    child:true,
    icon: GiftIcon,
    title: 'Plans',
    permission: PERMS.Dashboard,
    childrens :[
      {
        href: PREFIX + '/plans',
        title: 'Plans'
      },
      {
        href: PREFIX + '/free-plan',
        title: 'Free Plan'
      }
    ],
  },
  {
    child:true,
    icon: SettingsIcon,
    title: 'Settings',
    permission: PERMS.Dashboard,
    childrens :[
      {
        href: PREFIX + '/privacy',
        title: 'Privacy'
      },
      {
        href: PREFIX + '/about',
        title: 'About'
      },
      {
        href: PREFIX + '/message',
        title: 'Message'
      },
      {
        href: PREFIX + '/notification',
        title: 'Notification'
      },
      {
        href: PREFIX + '/app-versions',
        title: 'App Versions'
      }
    ],
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, role }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <ABox
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <ABox p={2}>
        <AList>
          {items.map((item, index) => {
            // if(!role) return null
            // if(!role || !role.permissions) return null
            // if(role.is_super ==  'false' && !role.permissions.includes(item.permission))return null
            return (
              <NavItem
                href={item.href}
                title={item.title}
                icon={item.icon}
                child = {item.child}
                childrens = {item.childrens}
                key={index}
              />
            )
          })}
        </AList>
      </ABox>
    </ABox>
  );

  return (
    <>
      <AHidden lgUp>
        <ADrawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </ADrawer>
      </AHidden>
      <AHidden mdDown>
        <ADrawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </ADrawer>
      </AHidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = state => ({
  role: state.authReducer.role
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps,mapDispatchToProps)(NavBar);

