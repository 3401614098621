import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Grid from '@material-ui/core/Grid';
const PageHeader = ({children,to, onClick, onExport, exportTitle, ...props}) => {
    return ( 
        <Grid className="flex-header">
            <Typography variant="h5">
             {children}
            </Typography>

            <div className="btn-groups">
            {props.export ?  
            <Button
            variant="contained"
            color="default"
            className="border-btn mr"
            onClick={onExport}
            startIcon={<DescriptionOutlinedIcon />}
            >
            {exportTitle?exportTitle:'Export CSV'}
            </Button>
           : null}
           {props.add ?  
            <Button
            variant="contained"
            color="default"
            to={to}
            className="bg-btn mr"
            component={Link}
            startIcon={<AddOutlinedIcon />}
            >
            Add New
            </Button>
            : null}
              {props.edit ?  
            <Button
            variant="contained"
            color="default"
            to={to}
            className="bg-btn mr"
            component={Link}
            startIcon={<EditIcon />}
            >
            Edit
            </Button>
            : null}

                {props.save ?  
            <Button
            variant="contained"
            color="default"
            className="bg-btn mr"
            onClick={onClick}
            >
            Save
            </Button>
            : null}
            </div>
        </Grid>
     );
}
PageHeader.defaultProps = {
    add : false,
    export: false,
    edit:false,
    save:false
}
export default PageHeader;