
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 15
  },
}));

export default function MultipleSelect({
    value,
    options,
    label,
    onChange,
    name,
    onInputChange,
    disabled,
    ...props
}) {

  const classes = useStyles();

  const onChangeSearch = (e)=>{
      if(onInputChange)onInputChange(e.target.value)
  }

  const onChangeSelect = (e, value)=>{
      if(onChange) onChange(value)
  }

  const currentOptions = options.map(option => {
    for(var i=0; i<value.length;i++){
      if(option.value === value[i]['value']) {
        return {...option, selected:true}
      }
    }
    return option
  }).filter((el)=> !el.selected)

  return (
    <Autocomplete
      id="combo-box-demo"
      className={classes.formControl}
      options={currentOptions}
      name={name}
      multiple
      disabled={disabled}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label}
      onChange={onChangeSelect}
      value={value}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <h4>{option.label}</h4>
        </React.Fragment>
      )}
      renderInput={params => {
          return <TextField {...params} 
                      label={label} 
                      variant="outlined" 
                      fullWidth 
                      onChange={onChangeSearch} />
      }}/>
  );
}

MultipleSelect.defaultProps = {
  value:[]
}