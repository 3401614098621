import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
   Card,
   CardHeader,
   CardContent,
} from '@material-ui/core'; 
import { 
   APage,
   AGrid,
   AInput,  
   AButton,
   AForm,
   ALoader,
   ASelect
} from '../../components/basic';
import PageHeader from '../../components/common/PageHeader';
import {
   CREATE_APP_VERSIONS
} from './api'
import { 
   MUTATION_CALL_BACK
} from '../../utils/graphql.config'

const TYPES = [
    {value:'android', label:'android'},
    {value:'ios', label:'ios'},
    {value:'executive ios', label:'executive ios'},
    {value:'executive android', label:'executive android'}
]

const AppVersionForm = () =>{

   const params = useParams()
   const [code, setCode] = useState('')
   const [message, setMessage] = useState('')
   const [type, setType] = useState(null)

   const SuccessMessage = 'Version saved saved successfully'
   const ErrorMessage = 'Version saving failed'

   const { register, handleSubmit, errors } = useForm(); 
   const mutationCallBack = (success)=>{
      if(success){
         setCode('')
         setMessage('')
         setType(null)
      }
   }

   const [addVersion, { loading }] = useMutation(CREATE_APP_VERSIONS, 
   MUTATION_CALL_BACK(SuccessMessage, ErrorMessage, mutationCallBack));

   const onSubmit = (data)=>{
       if(!type) return
      var data = {
         ...data, 
         version: parseFloat(data.version),
         type: type.value,
         updation: true
      }
      onSave({...data})
   }

   const onSave = (data)=>{
      addVersion({ 
         variables: { data }
      })
   }

   if(loading){
      return <ALoader/>
   }

   return(
      <APage>
         <PageHeader>App Version Form</PageHeader>
         <AGrid container spacing={2} className="page-details">
            <AGrid item md={12}> 
               <Card className="search-header page-form">
                     <CardHeader title="Version Info" />
                     <CardContent>
                     <AForm onSubmit={handleSubmit(onSubmit)}>
                     <AGrid container spacing={2}>
                        <AGrid item md={6}>
                        <AInput 
                              label="Version Code"
                              name="version"
                              errors={errors}
                              value={code}
                              onChange={(e)=>setCode(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                        <AInput 
                              label="Message"
                              name="message"
                              errors={errors}
                              value={message}
                              onChange={(e)=>setMessage(e.target.value)}
                              register={register}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={6}>
                        <ASelect 
                              label="Platform"
                              name="type"
                              errors={errors}
                              value={type}
                              onChange={(e)=>setType(e)}
                              register={register}
                              options={TYPES}
                              rules={{ required: true }}/>
                        </AGrid>
                        <AGrid item md={12}>
                           <AButton type="submit" variant="contained"
                           color="default"
                           className="bg-btn mr">SAVE</AButton>
                        </AGrid>
                     </AGrid>
                     </AForm>
                     </CardContent>
               </Card>           
            </AGrid> 
        </AGrid>
      </APage>   
   )
}
export default AppVersionForm;