import { gql } from '@apollo/client';
import { CONDITION_PARAM, CONDITION_VALUE } from '../../utils/graphql.config';

const GET_PAGE = gql`
query(${CONDITION_PARAM}){
    getOneContactPage(${CONDITION_VALUE}){
        id
      type
      message
      userType
    }
}
`

const CREATE_OR_UPDATE_PAGE = gql`
mutation($data: CreateContactPageDto!){
    createContactPage(data:$data){
      id
    }
  }
`

export {
    GET_PAGE,
    CREATE_OR_UPDATE_PAGE
}