import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { AInput, ALoader, APage } from '../../components/basic'
import AEditor from '../../components/basic/AEditor'
import PageHeader from '../../components/common/PageHeader'
import { MUTATION_CALL_BACK, QUERY_CALL_BACK } from '../../utils/graphql.config'
import { CREATE_OR_UPDATE_PAGE, GET_PAGE } from './api'

export default function Notification(){


    const SuccessNotification = 'Notification saved successfully'
    const ErrorNotification = 'Notification saving failed'
    const [data, setData] = useState('')
    const [link, setLink] = useState('')
    const [object, setObject] = useState(null)

    const mutationCallBack = (success)=>{
      
    }
 
    const queryCallBack = (success, data)=>{
        if(success && data){
            const obj = data.getOneContactPage
            if(obj){
                setData(obj.message.message)
                setLink(obj.message.link)
                setObject(obj)
            }
        }
    }
 
    const [updateNotification, { loading }] = useMutation(CREATE_OR_UPDATE_PAGE, 
    MUTATION_CALL_BACK(SuccessNotification, ErrorNotification, mutationCallBack));
 
    const { loading:getLoading } = useQuery(
        GET_PAGE, 
       { 
          variables: {condition: {type: 'notification'} }, 
          ...QUERY_CALL_BACK(queryCallBack)
       }
    );

    const onChange = (d)=>{
        setData(d)
    }

    const onSave = ()=>{
        if(!link || !data) return
        var d = {
            type:'notification',
            userType:'all',
            message:{
                message: data,
                link
            }
        }
        if(object && object.id){
            d['id'] = object.id
        }
        updateNotification({
            variables:{
                data:d
            }
        })
    }

    if(loading || getLoading){
        return <ALoader/>
    }

    return(
        <APage>
            <PageHeader export={false} save={true} onClick={onSave}>Notification</PageHeader>
            <AInput 
                label="Link"
                name="link"
                type="url"
                value={link}
                style={{
                    backgroundColor: 'white',
                    marginBottom: 10
                }}
                onChange={(e)=>setLink(e.target.value)}/>
            <AInput 
                label="Title"
                name="title"
                value={data}
                style={{
                    backgroundColor: 'white'
                }}
                onChange={(e)=>setData(e.target.value)}/>
        </APage>
    )
}