const cfg = {};

cfg.accountSid = 'ACce0b87df73cfb8b164966f73d72d230b';

cfg.twimlAppSid = 'AP9f68e79fa6147a498a67097cc84cbb66';
cfg.callerId = '+14787874463';

cfg.apiKey = 'SK3a690038df8778231f5503c86b77748b';
cfg.apiSecret = 'qoqdD8UshXMipIZd320F5FUkJmjj9SxT';

cfg.workspaceId = 'WSb4b41f0f421c5c0a8613a460514eab7e';
cfg.workerId = 'WK9d007568fe1a4c2e7cae98f5a79d7e28';

// Export configuration object
module.exports = cfg;
